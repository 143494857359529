import { cadastroProjetosAddUpdSaving } from "./kaizen-actions";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  Divider,
  Stack,
  Container,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import MaskedInput from "react-text-mask";
import { formatarParaSelect } from "../../../utils/utils";
import { maskToMoney } from "./kaizen-utils";
import Utils from "../../../utils/utils";
import { selectStyles, selectStylesDark } from "./styles-select";
import { Icone } from "../../../components";
import { useTranslation } from "react-i18next";

// FOrmulario alterar/incluir o saving do projeto
const FormSavingProjeto = ({ saving, dadosForm }) => {
  const { t } = useTranslation();
  // Extrai a lista de tipos saving e tipos bc
  const { lista_tipo_saving, lista_tipo_bc } = dadosForm;
  const lista_openbook = [
    ["S", "SIM"],
    ["N", "NÃO"],
  ];
  //
  const dispatch = useDispatch();
  const { aguardar } = useSelector((state) => state);
  // Verifica se saving tem dados e então cria os valores default de lista_tipo_saving e lista_tipo_bc
  let defaultTipoSaving, defaultTipoBC, defaultVaiParaOpenbook;
  if (saving.id_tipo_saving) {
    defaultTipoSaving = formatarParaSelect(
      lista_tipo_saving.filter(
        (ele) => String(ele[0]) === String(saving.id_tipo_saving)
      )
    )[0];
    defaultTipoBC = formatarParaSelect(
      lista_tipo_bc.filter((ele) => String(ele[0]) === String(saving.tipo_bc))
    )[0];

    defaultVaiParaOpenbook = formatarParaSelect(
      lista_openbook.filter(
        (ele) => String(ele[0]) === String(saving.vai_para_openbook)
      )
    )[0];
  }
  //
  const {
    formState: { errors },
    setValue,
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      id_tipo_saving: defaultTipoSaving ? defaultTipoSaving : null,
      tipo_bc: defaultTipoBC ? defaultTipoBC : null,
    },
  });
  //
  const fn = (val) => {
    const {
      valor_beneficio,
      valor_custo,
      bc,
      valor_saving,
      id_tipo_saving,
      tipo_bc,
      vai_para_openbook,
    } = val;
    // Primeiro passo é converter os valores monetarios
    const valores = [valor_beneficio, valor_custo, bc, valor_saving].map(
      (ele) => {
        let valor = parseFloat(Utils.desconverter(String(ele).trim())).toFixed(
          2
        );
        // Agora com o valor formatado para 2 casas decimais, transforme ele em um float
        valor = parseFloat(valor);
        return valor;
      }
    );

    // De tipo de saving e tipo_bc extraia os valores
    dispatch(
      cadastroProjetosAddUpdSaving({
        id_projeto: parseInt(saving.id_projeto),
        valor_beneficio: valores[0],
        valor_custo: valores[1],
        bc: valores[2],
        saving: valores[3],
        id_tipo_saving: id_tipo_saving.value,
        tipo_bc: tipo_bc.value,
        vai_para_openbook: vai_para_openbook.value,
      })
    );
  };

  // Funcao de callback que deve ser acionada nas alterações dos campos beneficio e custo
  const onChangeValor = (val, campo) => {
    // Pega os valores de beneficio e custo
    const val_bene = parseFloat(
      campo === "valor_beneficio"
        ? Utils.desconverter(val.trim())
        : Utils.desconverter(watch("valor_beneficio"))
    );
    const val_custo = parseFloat(
      campo === "valor_custo"
        ? Utils.desconverter(val.trim())
        : Utils.desconverter(watch("valor_custo").trim())
    );

    // Tenta dividir beneficio por custo
    let val_bc, val_saving;
    try {
      val_bc = (val_bene / val_custo).toFixed(2);
    } catch (err) {
      val_bc = 0.0;
    }
    // Reduz o valor de beneficio do custo
    val_saving = (val_bene - val_custo).toFixed(2);

    if (isNaN(val_saving) || !isFinite(val_saving)) {
      val_saving = 0.0;
    }
    if (isNaN(val_bc) || !isFinite(val_bc)) {
      val_bc = 0.0;
    }
    //
    setValue("bc", Utils.converter(val_bc));
    setValue("valor_saving", Utils.converter(val_saving));
    setValue(campo, val);
  };
  // Converter os valores default dos campos monetarios
  let defaultBeneficio = "0,00",
    defaultBC = "0,00",
    defaultSaving = "0,00",
    defaultCusto = "0,00";
  if (saving?.valor_beneficio) {
    defaultBeneficio = Utils.converter(
      saving.valor_beneficio.toFixed(2)
    ).replace("R$", "");
    defaultCusto = Utils.converter(saving.valor_custo.toFixed(2)).replace(
      "R$",
      ""
    );
    defaultBC = Utils.converter(saving.bc.toFixed(2));
    defaultSaving = Utils.converter(saving.saving.toFixed(2));
  }

  const isDarkMode = useTheme()?.palette.mode === "dark";

  return (
    <Container sx={{ py: 2 }} maxWidth="lg">
      <Typography align="center" variant="h6">
        {t("cadastro_projetos.formDateSaving")}
      </Typography>
      <Divider />
      <Stack sx={{ mt: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Controller
            name="valor_beneficio"
            defaultValue={defaultBeneficio}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <MaskedInput
                mask={maskToMoney}
                {...field}
                onKeyUp={(e) =>
                  onChangeValor(e.target.value, "valor_beneficio")
                }
                inputMode="numeric"
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label={t("cadastro_projetos.valBenefic")}
                    sx={{ my: 1, mr: 1 }}
                    //InputProps={{ startAdornment: 'R$'}}
                    inputProps={{ autoComplete: "off" }}
                    size="small"
                    {...props}
                    inputRef={ref}
                    placeholder="0,00"
                    error={!!errors.valor_beneficio}
                    helperText={!!errors.valor_beneficio && "0,00"}
                  />
                )}
              />
            )}
          />

          <Controller
            name="valor_custo"
            defaultValue={defaultCusto}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <MaskedInput
                mask={maskToMoney}
                {...field}
                onKeyUp={(e) => onChangeValor(e.target.value, "valor_custo")}
                inputMode="numeric"
                render={(ref, props) => (
                  <TextField
                    fullWidth
                    label={t("cadastro_projetos.valCust")}
                    sx={{ my: 1, mr: 1 }}
                    placeholder="0,00"
                    //InputProps={{ startAdornment: 'R$'}}
                    inputProps={{ autoComplete: "off" }}
                    size="small"
                    {...props}
                    inputRef={ref}
                    error={!!errors.valor_custo}
                    helperText={!!errors.valor_custo && "0,00"}
                  />
                )}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }}>
          <Controller
            name="bc"
            defaultValue={defaultBC}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                fullWidth
                disabled
                {...field}
                label={t("cadastro_projetos.valBC")}
                sx={{ my: 1, mr: 1 }}
                inputProps={{ autoComplete: "off" }}
                size="small"
                error={!!errors.bc}
                helperText={!!errors.bc && "0,00"}
              />
            )}
          />

          <Controller
            name="valor_saving"
            defaultValue={defaultSaving}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                fullWidth
                disabled
                {...field}
                label={t("cadastro_projetos.valSaving")}
                sx={{ my: 1, mr: 1 }}
                inputProps={{ autoComplete: "off" }}
                size="small"
                error={!!errors.saving}
                helperText={!!errors.saving && "0,00"}
              />
            )}
          />
        </Stack>
        {/*  */}
        <Controller
          name="id_tipo_saving"
          control={control}
          defaultValue={defaultTipoSaving}
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("cadastro_projetos.typeSaving")}
              </Typography>
              <Select
                {...field}
                options={formatarParaSelect(lista_tipo_saving)}
                styles={isDarkMode ? selectStylesDark : selectStyles}
              />
              {!!errors.id_tipo_saving && (
                <Typography align="center" color="error" variant="caption">
                  {t("cadastro_projetos.fieldRequired")}
                </Typography>
              )}
            </Stack>
          )}
        />
        <Controller
          name="tipo_bc"
          control={control}
          defaultValue={defaultTipoBC}
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("cadastro_projetos.typeBC")}
              </Typography>
              <Select
                {...field}
                options={formatarParaSelect(lista_tipo_bc)}
                styles={isDarkMode ? selectStylesDark : selectStyles}
              />
              {!!errors.tipo_bc && (
                <Typography align="center" color="error" variant="caption">
                  {t("cadastro_projetos.fieldRequired")}
                </Typography>
              )}
            </Stack>
          )}
        />
        <Controller
          name="vai_para_openbook"
          control={control}
          defaultValue={defaultVaiParaOpenbook}
          rules={{ required: true }}
          render={({ field }) => (
            <Stack sx={{ mb: 1 }}>
              <Typography variant="subtitle2" align="center">
                {t("cadastro_projetos.runToOpenBook")}
              </Typography>
              <Select
                {...field}
                options={formatarParaSelect(lista_openbook)}
                styles={isDarkMode ? selectStylesDark : selectStyles}
              />
              {!!errors.tipo_bc && (
                <Typography align="center" color="error" variant="caption">
                  {t("cadastro_projetos.fieldRequired")}
                </Typography>
              )}
            </Stack>
          )}
        />
        <Stack alignItems="center">
          <Button
            disabled={aguardar}
            onClick={handleSubmit(fn)}
            color="primary"
            variant="contained"
            startIcon={
              aguardar ? (
                <CircularProgress siz={20} />
              ) : (
                <Icone icone="PriceCheck" />
              )
            }
          >
            {t("cadastro_projetos.register")}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default FormSavingProjeto;
