import React, { memo, useEffect, useState } from "react";
import {
  useTheme,
  Paper,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { Route, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { tamanhoCamposGet, infoUsuarioGetDados } from "./redux/actions";
import "./css/sidebar.scss";
//
import { BarraInferior, Header } from "./components";
import "./App.css";
import Splash from "./splash";
//
import { AreaDoGestor, MinhaEquipe } from "./routers/gestor";
import {
  Avaliacao,
  Ouvidoria,
  FeedRH,
  GaleriaDeFotos,
  DadosCadastrais,
  RegistroEmocional,
  Documentos,
  Oportunidades,
  InformeRendimentos,
  Treinamento,
  TreinamentoView,
  TreinamentoCursos,
  TreinamentoInstrutor,
  RelatorioFerias,
  DocumentosSGSI,
} from "./routers/rh";
import {
  PontoEletronico,
  ContraCheque,
  Absenteismo,
  FuncionarioTamg,
  BancoDeHoras,
  FuncionarioTape,
} from "./routers/ponto";
import {
  Colaboradores,
  Estatisticas,
  ManutencaoVariaveis,
  PainelDeNotificacao,
} from "./routers/admin";

import {
  CaixaDeMensagem,
  Configuracoes,
  ForcaAlteracaoSenha,
} from "./routers/raiz";
import {
  RainbowChart,
  CadastroProjetos,
  GestaoProjetos,
  Workload,
  ManutencaoAtivos,
  ManutencaoArea,
  ManutencaoPilar,
  ManutencaoPerda,
  ManutencaoModelo,
  Saving,
  GestaoAlmoxarifado,
  GerenciaEstoque,
} from "./routers/projetos";
import CartaoSeguranca from "./routers/cartao";
import { ManutencaoComponentes } from "./routers/estruturas";
import { ToastContainer } from "react-toastify";

import { blue, grey } from "@mui/material/colors";
import { useDarkMode } from "./hooks";
import { GestaoHelpdesk, GestaoTickets, HelpDesk } from "./routers/ti";
import { HelpdeskDetalhes, HelpDeskAdicionar } from "./routers/ti/helpdesk/";
import {
  rotaGestaoTickets,
  rotaHelpdesk,
  rotaHelpdeskAdicionar,
  rotaHelpdeskDetalhes,
  rotaHelpdeskFiltroAvancado,
} from "./routers/routes";

import { rotaMenus } from "./routers/routes";
import ListMenusMobile from "./routers/lista-menus-mobile";
import HelpdeskFiltroAvancado from "./routers/ti/helpdesk/helpdesk-filtro-avancado";
import OuvidoriaAdd from "./routers/rh/ouvidoria/ouvidoria-add";
//import BarraInferiorLegado from "./components/barra-inferior_legacy";
import "react-toastify/dist/ReactToastify.css";
import OuvidoriaDetalhes from "./routers/rh/ouvidoria/ouvidoria-detalhes";
import OuvidoriaBuscaPorToken from "./routers/rh/ouvidoria/ouvidoria-busca-por-token";
import OuvidoriaFiltroAvancado from "./routers/rh/ouvidoria/ouvidoria-filtro-avancado";
import {
  RnciNcp,
  Escalonada,
  EscalonadaCalendarioVer,
} from "./routers/qualidade";
import RnciNcpForm from "./routers/qualidade/rnci-ncp/rnci-ncp-form";
import RnciDetalhes from "./routers/qualidade/rnci-ncp/rnci-detalhes";
import RnciFormSubida from "./routers/qualidade/rnci-ncp/rnci-form-subida";
import RnciFormQualidade from "./routers/qualidade/rnci-ncp/rnci-form-qualidade";
import MinhaEquipeGerarPdfAvaliacao from "./routers/gestor/minha_equipe/minha-equipe-gerar-pdf";
import { Lesson } from "./routers/lesson";
import LessonForm from "./routers/lesson/lesson/lesson_form";
import LessonView from "./routers/lesson/lesson/lesson_view";
import Manutencao from "./routers/manutencao/manutencao";
import Login from "./routers/login";
import Budget from "./routers/budget/budget";
import HelpdeskAssunto from "./routers/ti/helpdesk/helpdesk-assunto";
import HelpdeskStatus from "./routers/ti/helpdesk/helpdesk-status";

import Ncp from "./routers/qualidade/ncp/ncp";
import NcpForm from "./routers/qualidade/ncp/ncp-form";
import NcpDetalhes from "./routers/qualidade/ncp/ncp-detalhes";
import Ferias from "./routers/rh/ferias/ferias";
import FeriasAssinar from "./routers/rh/ferias/ferias-assinar";
import HelpdeskCriticidade from "./routers/ti/helpdesk/helpdesk-criticidade";
import Twttp from "./routers/projetos/twttp";
import { LogisticaBI } from "./routers/logistica";

// Coloque a URL da rota aqui e o componente que representa esta rota.
const rotas = [
  ["/", Login],
  ["/splash", Splash],
  ["/caixa_de_mensagem", CaixaDeMensagem],
  ["/area_do_gestor", AreaDoGestor],
  ["/avaliacao", Avaliacao],
  ["/ponto_eletronico", PontoEletronico],
  [ContraCheque.rota, ContraCheque],
  [Colaboradores.rota, Colaboradores],
  [Configuracoes.rota, Configuracoes],
  [Ouvidoria.rota, Ouvidoria],
  [OuvidoriaAdd.rota, OuvidoriaAdd],
  [OuvidoriaDetalhes.rota, OuvidoriaDetalhes],
  [OuvidoriaBuscaPorToken.rota, OuvidoriaBuscaPorToken],
  [OuvidoriaFiltroAvancado.rota, OuvidoriaFiltroAvancado],
  [MinhaEquipe.rota, MinhaEquipe],
  [MinhaEquipeGerarPdfAvaliacao.rota, MinhaEquipeGerarPdfAvaliacao],
  ["/cadastro_projetos", CadastroProjetos],
  [Estatisticas.rota, Estatisticas],
  [ManutencaoVariaveis.rota, ManutencaoVariaveis],
  [CartaoSeguranca.rota, CartaoSeguranca],
  [FeedRH.rota, FeedRH],
  [GaleriaDeFotos.rota, GaleriaDeFotos],
  [Absenteismo.rota, Absenteismo],
  ["/gestao_projetos", GestaoProjetos],
  ["/workload", Workload],
  [Twttp.rota, Twttp],
  [ManutencaoAtivos.rota, ManutencaoAtivos],
  [ManutencaoArea.rota, ManutencaoArea],
  [ManutencaoModelo.rota, ManutencaoModelo],
  [ManutencaoPilar.rota, ManutencaoPilar],
  [ManutencaoPerda.rota, ManutencaoPerda],
  ["/rainbow_chart", RainbowChart],
  [DadosCadastrais.rota, DadosCadastrais],
  ["/saving", Saving],
  [RegistroEmocional.rota, RegistroEmocional],
  ["/funcionario_tamg", FuncionarioTamg],
  [BancoDeHoras.rota, BancoDeHoras],
  ["/funcionario_tape", FuncionarioTape],
  [Documentos.rota, Documentos],
  [PainelDeNotificacao.rota, PainelDeNotificacao],
  [Oportunidades.rota, Oportunidades],
  [ManutencaoComponentes.rota, ManutencaoComponentes],
  ["/forca_alteracao_senha", ForcaAlteracaoSenha],
  [rotaHelpdesk, HelpDesk],
  [rotaHelpdeskDetalhes, HelpdeskDetalhes],
  [rotaHelpdeskAdicionar, HelpDeskAdicionar],
  [rotaMenus, ListMenusMobile],
  [rotaHelpdeskFiltroAvancado, HelpdeskFiltroAvancado],
  [InformeRendimentos.rota, InformeRendimentos],
  [rotaGestaoTickets, GestaoTickets],
  [RnciNcp.rota, RnciNcp],
  [RnciNcpForm.rota, RnciNcpForm],
  [RnciDetalhes.rota, RnciDetalhes],
  [RnciFormSubida.rota, RnciFormSubida],
  [RnciFormQualidade.rota, RnciFormQualidade],
  [GestaoAlmoxarifado.rota, GestaoAlmoxarifado],
  [GerenciaEstoque.rota, GerenciaEstoque],
  [Lesson.rota, Lesson],
  [LessonForm.rota, LessonForm],
  [LessonView.rota, LessonView],
  [Manutencao.rota, Manutencao],
  [Budget.rota, Budget],
  [HelpdeskAssunto.rota, HelpdeskAssunto],
  [HelpdeskStatus.rota, HelpdeskStatus],
  [GestaoHelpdesk.rota, GestaoHelpdesk],
  [Ncp.rota, Ncp],
  [NcpForm.rota, NcpForm],
  [NcpDetalhes.rota, NcpDetalhes],
  [Ferias.rota, Ferias],
  [FeriasAssinar.rota, FeriasAssinar],
  [HelpdeskCriticidade.rota, HelpdeskCriticidade],
  [Treinamento.rota, Treinamento],
  [TreinamentoView.rota, TreinamentoView],
  [TreinamentoCursos.rota, TreinamentoCursos],
  [TreinamentoInstrutor.rota, TreinamentoInstrutor],
  [Escalonada.rota, Escalonada],
  [EscalonadaCalendarioVer.rota, EscalonadaCalendarioVer],
  [RelatorioFerias.rota, RelatorioFerias],
  [LogisticaBI.rota, LogisticaBI],
  [DocumentosSGSI.rota, DocumentosSGSI],
];

const routesWithoutSidebar = [
  "/",
  "/splash",
  "/forca_alteracao_senha",
  MinhaEquipeGerarPdfAvaliacao.rota,
];

const App = (props) => {
  const history = useHistory();
  const [rotaDefault, setRotaDefault] = useState(window.location.pathname);
  // const {
  //   breakpoints: { values },
  // } = useTheme();
  // const isMobile = values["md"] > window.innerWidth;
  const dispatch = useDispatch();
  useEffect(() => {
    if (window.location.pathname !== "/") {
      dispatch(infoUsuarioGetDados());
      dispatch(tamanhoCamposGet());
    }
  }, [dispatch]);
  // Obter informacoes sobre o tamanho dos campos
  // useEffect(() => dispatch(tamanhoCamposGet()), [dispatch]);
  // Ouvir alteracoes na navegacao para setar o menu selecionado corretamente
  useEffect(() => {
    const unlisten = history.listen(() => {
      const { location } = history;
      setRotaDefault(location.pathname);
    });
    return unlisten;
  }, [history, setRotaDefault]);
  //
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));

  //
  const [darkMode, setDarkMode] = useDarkMode();

  const theme = createTheme({
    isMobile,
    backgroundPage: !darkMode && grey[200],
    palette: {
      mode: darkMode ? "dark" : "light",
      primary: darkMode
        ? {
            main: blue["A200"],
            dark: "#012258",
            //main: red['A700'],
          }
        : {
            main: "#005387",
            dark: "#012258",
          },
      secondary: {
        //main: '#1b5e20',
        main: "#9c27b0",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="bottom-center" />
      <Paper elevation={0} style={{ minHeight: "100vh" }}>
        <HeaderAndSideBar setDarkMode={setDarkMode} />
        {rotas.map((ele, idx) => (
          <Route key={idx} exact={true} path={ele[0]} component={ele[1]} />
        ))}
        {isMobile && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
        {isMobile &&
          !routesWithoutSidebar.includes(rotaDefault) &&
          rotaDefault !== rotaMenus && <BarraInferior rotaMenus={rotaMenus} />}

        {/* {isMobile && !routesWithoutSidebar.includes(rotaDefault) && (
          <BarraInferiorLegado rotaMenus={rotaMenus} />
        )} */}
      </Paper>
    </ThemeProvider>
  );
};
// Componente para exibir o header e barra lateral
const HeaderAndSideBar = memo(({ setDarkMode }) => {
  //const location = useLocation();

  return (
    !routesWithoutSidebar.includes(window.location.pathname) && (
      <>
        <Header setDarkMode={setDarkMode} />
        {/* <SideBar /> */}
      </>
    )
  );
});

export default App;
