import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Typography,
  Stack,
  FormControlLabel,
  Switch,
  Button,
  Container,
} from "@mui/material";
import Select from "react-select";
import { useTheme } from "@mui/material/styles";
import { formatarParaSelect } from "../../../utils/utils";
import { cadastroProjetosFiltroAvancado } from "./kaizen-actions";
import { FiltroAvancado, Icone } from "../../../components";
import { selectStyles, selectStylesDark } from "./styles-select";
import { useTranslation } from "react-i18next";

const selectPilar = (state) => state?.cadastroProjetos?.dadosForm?.pilar;
const selectThree = (state) => state?.cadastroProjetos?.dadosForm?.arvore;
const selectCodes = (state) => state?.cadastroProjetos?.dadosForm?.codigos;
const selectTypeOfProject = (state) =>
  state?.cadastroProjetos?.dadosForm?.tipo_de_projeto;
const selectWait = (state) => state?.aguardar;

const ComponenteParaFiltroAvancado = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pilar = useSelector(selectPilar);
  const arvore = useSelector(selectThree);
  const codigos = useSelector(selectCodes);
  const tipoDeProjeto = useSelector(selectTypeOfProject);
  const openBook = [
    ["S", "SIM"],
    ["N", "NÃO"],
  ];

  const aguardar = useSelector(selectWait);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [filtroSelecionado, setFiltroSelecionado] = useState([]);

  const {
    breakpoints: { values },
  } = useTheme();
  const isMobile = values["sm"] > window.innerWidth;

  const corpo = [];

  const isDarkMode = useTheme()?.palette.mode === "dark";

  if (filtroSelecionado.includes(t("cadastro_projetos.code"))) {
    corpo.push(
      <Controller
        key="codigo"
        control={control}
        name="codigo"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.code")}
            </Typography>
            <Select
              options={formatarParaSelect(codigos)}
              isMulti
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.codigo && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorProjectCode")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }

  if (filtroSelecionado.includes(t("cadastro_projetos.plant"))) {
    corpo.push(
      <Controller
        key="planta"
        control={control}
        name="planta"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.plant")}
            </Typography>
            <Select
              options={formatarParaSelect(["TAPE", "TAMG"])}
              isMulti
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.planta && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorPlant")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
  if (filtroSelecionado.includes(t("cadastro_projetos.pilar"))) {
    corpo.push(
      <Controller
        key="pilar"
        control={control}
        name="pilar"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.pilar")}
            </Typography>
            <Select
              options={formatarParaSelect(pilar)}
              isMulti
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.pilar && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorPilar")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
  if (filtroSelecionado.includes(t("cadastro_projetos.sector"))) {
    corpo.push(
      <Controller
        key="setor"
        control={control}
        name="setor"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.sector")}
            </Typography>
            <Select
              options={formatarParaSelect(arvore)}
              isMulti
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.pilar && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorSector")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
  if (filtroSelecionado.includes(t("cadastro_projetos.late"))) {
    corpo.push(
      <Controller
        key="atrasados"
        control={control}
        name="atrasados"
        defaultValue=""
        render={({ field }) => (
          <FormControlLabel
            sx={{ mx: 1 }}
            {...field}
            disabled={aguardar}
            control={<Switch />}
            label={t("cadastro_projetos.lateFilterAdvanced")}
          />
        )}
      />
    );
  }
  if (filtroSelecionado.includes(t("cadastro_projetos.typeOfProject"))) {
    corpo.push(
      <Controller
        key="tipo_de_projeto"
        control={control}
        name="tipo_de_projeto"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.typeOfProject")}
            </Typography>
            <Select
              options={formatarParaSelect(tipoDeProjeto)}
              isMulti
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.tipo_de_projeto && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorTypeOfProject")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
  //
  if (filtroSelecionado.includes(t("cadastro_projetos.openBook"))) {
    corpo.push(
      <Controller
        key="open_book"
        control={control}
        name="open_book"
        defaultValue=""
        rules={{ required: true }}
        render={({ field }) => (
          <Stack sx={{ my: 1 }}>
            <Typography variant="subtitle2">
              {t("cadastro_projetos.openBook")}
            </Typography>
            <Select
              options={formatarParaSelect(openBook)}
              styles={isDarkMode ? selectStylesDark : selectStyles}
              {...field}
              isDisabled={aguardar}
            />
            {!!errors.open_book && (
              <Typography variant="caption" color="error" align="center">
                {t("cadastro_projetos.errorOpenBook")}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
  //
  const fn = (val) => {
    const obj = {};
    // Passa sobre cada item e cria o objeto da consulta
    filtroSelecionado.forEach((key) => {
      let chaveCampo = key.toLowerCase();

      if (key === t("cadastro_projetos.code")) chaveCampo = "codigo";
      if (key === t("cadastro_projetos.typeOfProject"))
        chaveCampo = "tipo_de_projeto";
      if (key === t("cadastro_projetos.plant")) chaveCampo = "planta";
      if (key === t("cadastro_projetos.pilar")) chaveCampo = "pilar";
      if (key === t("cadastro_projetos.sector")) chaveCampo = "setor";
      if (key === t("cadastro_projetos.late")) chaveCampo = "atrasados";
      if (key === t("cadastro_projetos.openBook")) chaveCampo = "open_book";

      // Se for dos atrasados deve ser verdadeiro
      if (chaveCampo === "atrasados" && val[chaveCampo]) {
        obj[chaveCampo] = true;
      } else if (chaveCampo === "open_book") {
        obj[chaveCampo] = val[chaveCampo].value;
      } else if (val[chaveCampo]) {
        obj[chaveCampo] = val[chaveCampo].map((ele) => ele.value).join(",");
      }
    });

    dispatch(cadastroProjetosFiltroAvancado(obj));
  };

  return (
    <FiltroAvancado
      onClick={(valor) => {
        setFiltroSelecionado((state) => {
          if (state.includes(valor)) {
            const novoValor = state.slice();
            const index = novoValor.indexOf(valor);
            novoValor.splice(index, 1);
            return novoValor;
          }
          return [...state, valor];
        });
      }}
      opcoes={[
        {
          titulo: t("cadastro_projetos.code"),
          icone: <Icone icone="Search" />,
        },
        {
          titulo: t("cadastro_projetos.pilar"),
          icone: <Icone icone="StackedBarChart" />,
        },
        {
          titulo: t("cadastro_projetos.plant"),
          icone: <Icone icone="PrecisionManufacturing" />,
        },
        {
          titulo: t("cadastro_projetos.sector"),
          icone: <Icone icone="AccountTree" />,
        },
        {
          titulo: t("cadastro_projetos.late"),
          icone: <Icone icone="EventBusy" />,
        },
        {
          titulo: t("cadastro_projetos.typeOfProject"),
          icone: <Icone icone="MergeType" />,
        },
        {
          titulo: t("cadastro_projetos.openBook"),
          icone: <Icone icone="Book" />,
        },
      ]}
    >
      <Container maxWidth="md">
        {corpo}

        {corpo.length > 0 && (
          <Typography align="center">
            <Button
              disabled={aguardar}
              fullWidth={isMobile}
              onClick={handleSubmit(fn)}
              variant="contained"
            >
              {t("cadastro_projetos.applyFilter")}
            </Button>
          </Typography>
        )}
      </Container>
    </FiltroAvancado>
  );
};

export default ComponenteParaFiltroAvancado;
