import React, { useEffect, useState, useReducer, useCallback } from "react";
import {
  useTheme,
  useMediaQuery,
  Grid,
  Breadcrumbs,
  Button,
  Stack,
  Paper,
  CircularProgress,
  List,
  Grow,
  Fab,
} from "@mui/material";
import BackgroundRouter from "../../background-router";
import { useFetch } from "../../../hooks";
import { AnimacaoSemDados, Body1, FabMulti, Icone } from "../../../components";

import _ from "lodash";
import { DrawerDialog } from "../../../extra-components";
import { useSelector } from "react-redux";
import { pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { selectUploadDocumentos } from "./documentos-seletores";
import { ACTIONS, ROTA, fnMoverArquivo } from "./documentos-utils";
import DocumentosModal from "./documentos-modal";
import SuperModal from "./documentos-super-modal";
import ItemArquivo from "./documentos-item-file";
import ItemArquivoMobile from "./documentos-item-file-mobile";
import { useTranslation } from "react-i18next";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SUPER_MODAL:
      return {
        ...state,
        superModal: action.data,
      };
    case ACTIONS.VER_MODAL: // Orienta para exibicao do modal
      return {
        ...state,
        modal: action.data,
      };
    case ACTIONS.FECHAR_MODAL: // Orienta para fechar o modal
      return {
        ...state,
        modal: null,
        superModal: null,
      };
    case ACTIONS.INIT: // Inicializa os dados
      return action.data;

    case ACTIONS.UPD: // Atualiza arquivo(s) na lista atual
      return (() => {
        const { arquivos } = state;
        const cloneArq = _.map(_.clone(arquivos), (val) => {
          if (String(val.id_arquivo) === String(action.data.id_arquivo)) {
            return action.data;
          }
          return val;
        });
        return {
          ...state,
          arquivos: cloneArq,
        };
      })();
    case ACTIONS.DEL: // Remover arquivo
      return (() => {
        const { arquivos } = state;
        const cloneArq = _.filter(_.clone(arquivos), (val) => {
          if (String(val.id_arquivo) === String(action.data)) {
            return false;
          }
          return true;
        });
        return {
          ...state,
          arquivos: cloneArq,
        };
      })();
    case ACTIONS.ADD:
      return {
        ...state,
        arquivos: [...state.arquivos, ...action.data],
      };

    default:
      return state;
  }
};

//
function Documentos() {
  const [dirAtual, setDirAtual] = useState("raiz");

  const [stateDocumentos, dispatch] = useReducer(reducer, {});
  const addItem = useCallback(
    (obj) => {
      dispatch({ data: obj, type: ACTIONS.ADD });
      dispatch({ type: ACTIONS.FECHAR_MODAL });
    },
    [dispatch]
  );

  const { data, wait, setFetch } = useFetch(ROTA, "GET");
  const isMobile = useMediaQuery(useTheme()?.breakpoints?.down("md"));
  // Solicita dados
  useEffect(() => {
    setFetch({ dirPai: dirAtual });
  }, [setFetch, dirAtual]);
  //
  useEffect(() => {
    dispatch({ data: data, type: ACTIONS.INIT });
  }, [data, dispatch]);

  return (
    <BackgroundRouter>
      {stateDocumentos?.superModal && (
        <SuperModal modal={stateDocumentos.superModal} dispatch={dispatch} />
      )}
      {stateDocumentos?.modal && (
        <DrawerDialog
          fnGetCorpo={() => (
            <DocumentosModal
              modal={stateDocumentos.modal}
              dispatch={dispatch}
              navegarPara={setDirAtual}
            />
          )}
          fecharModal={() => dispatch({ type: ACTIONS.FECHAR_MODAL })}
        />
      )}
      {isMobile ? (
        <TelaMobile
          {...stateDocumentos}
          aguardar={wait}
          onNavegar={setDirAtual}
          dispatch={dispatch}
          onAddItem={addItem}
        />
      ) : (
        <TelaDesktop
          {...stateDocumentos}
          aguardar={wait}
          onNavegar={setDirAtual}
          dispatch={dispatch}
          onAddItem={addItem}
        />
      )}
    </BackgroundRouter>
  );
}
// Estrutura que exibe como a tela mobile vai ser exibida
const TelaMobile = ({
  aguardar,
  path,
  arquivos,
  is_proprietario,
  permissao_pasta_visualizacao,
  mat_planta_proprietario,
  colaboradores,
  onNavegar,
  onAddItem,
  dispatch,
}) => {
  const { t } = useTranslation();
  const [moverArquivo, setMoverArquivo] = useState(null); // Usado para movimentar arquivos
  const isSendFiles = useSelector(selectUploadDocumentos); // Verifica se o usuario pode enviar arquivos

  //
  let listaFabs = [];
  // Se for do RH tem as opções
  if (isSendFiles) {
    listaFabs = [
      {
        icon: "RestoreFromTrash",
        title: t("documentos.titleAccessTrash"),
        color: "primary",
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.ACESSAR_LIXEIRA,
              dados: { colaboradores },
            },
          });
        },
      },
    ];
    listaFabs.push({
      icon: "FileUpload",
      title: t("documentos.titleUploadFiles"),
      color: "primary",
      onClick: () => {
        dispatch({
          type: ACTIONS.VER_MODAL,
          data: {
            tipo: DocumentosModal.modal.ENVIAR_ARQUIVO,
            dados: {
              path,
              onAddItem,
              colaboradores,
              permissao_pasta_visualizacao,
              mat_planta_proprietario,
            },
          },
        });
      },
    });
  }
  // So exibe estes botoes se o usuario for proprietario da pasta
  if (is_proprietario) {
    listaFabs.push({
      icon: "CreateNewFolder",
      title: t("documentos.titleCreateFolder"),
      color: "primary",
      onClick: () => {
        dispatch({
          type: ACTIONS.VER_MODAL,
          data: {
            tipo: DocumentosModal.modal.CRIAR_PASTA,
            dados: {
              path,
              onAddItem,
              colaboradores,
              permissao_pasta_visualizacao,
              mat_planta_proprietario,
            },
          },
        });
      },
    });
  }

  return (
    <Stack sx={{ width: "100vw" }}>
      <Navegacao
        onAddItem={onAddItem}
        path={path}
        onNavegar={onNavegar}
        arquivos={arquivos}
        moverArquivo={moverArquivo}
        setMoverArquivo={setMoverArquivo}
      />
      {aguardar ? (
        <CircularProgress />
      ) : (
        <GridItensDaPasta
          isMobile
          onNavegar={onNavegar}
          arquivos={arquivos}
          dispatch={dispatch}
          onRecortar={setMoverArquivo}
          itemRecortado={moverArquivo}
          colaboradores={colaboradores}
        />
      )}
      {listaFabs?.length > 0 && <FabMulti fabs={listaFabs} color="primary" />}
    </Stack>
  );
};
// Componente que é a barra superior para navegacao e colagem de arquivos
const Navegacao = ({
  path,
  onNavegar,
  arquivos,
  moverArquivo,
  setMoverArquivo,
  onAddItem,
  optionsAdminUser,
}) => {
  const { t } = useTranslation();
  // Callback para colar um arquivo em um diretorio
  const fnColar = useCallback(() => {
    const formData = {
      id_arquivo: moverArquivo,
      diretorio_pai: path?.split("/").pop(),
    };

    fnMoverArquivo(formData, onAddItem, () => setMoverArquivo(null), t);
  }, [setMoverArquivo, path, moverArquivo, onAddItem, t]);

  return (
    <>
      <Stack
        direction="row"
        elevation={0}
        sx={{
          width: { xs: "100vw", md: "100%" },
          my: 1,
          borderRadius: 0,
        }}
      >
        <Breadcrumbs
          flex={1}
          maxItems={2}
          aria-label="breadcrumb"
          separator={<Icone sx={{ fontSize: 20 }} icone="ChevronRight" />}
        >
          {path?.split("/")?.map((ele, idx) => (
            <Button
              size="small"
              onClick={() => onNavegar(ele)}
              variant={
                idx === path.split("/").length - 1 ? "contained" : "text"
              }
              key={idx}
            >
              {ele}
            </Button>
          ))}
        </Breadcrumbs>
        {optionsAdminUser && (
          <Stack direction="row" alignItems="center" gap={1}>
            {optionsAdminUser.map((ele, idx) => (
              <Fab
                title={ele.title}
                size="small"
                variant="extended"
                color={ele.color}
                key={idx}
                onClick={ele.onClick}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Icone icone={ele.icon} />
                  <Body1>{ele.title}</Body1>
                </Stack>
              </Fab>
            ))}
          </Stack>
        )}
      </Stack>
      <Grow in={moverArquivo} unmountOnExit={true}>
        <Stack
          sx={{ px: 1, py: { xs: 0, md: 1 } }}
          spacing={1}
          direction="row"
          alignItems="center"
        >
          <Button
            size="medium"
            fullWidth
            startIcon={<Icone icone="Cancel" />}
            variant="text"
            onClick={() => setMoverArquivo(null)}
          >
            {t("documentos.labelBtnCancel")}
          </Button>
          <Button
            disabled={
              _.filter(arquivos, (val) => val.id_arquivo === moverArquivo)
                ?.length > 0
            }
            size="medium"
            fullWidth
            startIcon={<Icone icone="ContentPaste" />}
            variant="contained"
            onClick={fnColar}
          >
            {t("documentos.labelBtnPaste")}
          </Button>
        </Stack>
      </Grow>
    </>
  );
};
// Tela desktop
const TelaDesktop = ({
  path,
  dispatch,
  onAddItem,
  onNavegar,
  is_proprietario,
  arquivos,
  permissao_pasta_visualizacao,
  mat_planta_proprietario,
  colaboradores,
}) => {
  const { t } = useTranslation();
  const backgroundPage = useTheme()?.backgroundPage;
  const isSendFiles = useSelector(selectUploadDocumentos); // Verifica se o usuario pode enviar arquivos
  const [moverArquivo, setMoverArquivo] = useState(null); // Usado para movimentar arquivos

  let optionsAdminUser = [];
  // Se for do RH tem as opções
  if (is_proprietario) {
    optionsAdminUser = [
      {
        icon: "CreateNewFolder",
        title: t("documentos.titleCreateFolder"),
        color: "primary",
        onClick: () => {
          dispatch({
            type: ACTIONS.VER_MODAL,
            data: {
              tipo: DocumentosModal.modal.CRIAR_PASTA,
              dados: {
                path,
                onAddItem,
                colaboradores,
                permissao_pasta_visualizacao,
                mat_planta_proprietario,
              },
            },
          });
        },
      },
    ];
  }
  //
  if (isSendFiles) {
    optionsAdminUser.push({
      icon: "FileUpload",
      title: t("documentos.titleUploadFiles"),
      color: "primary",
      onClick: () => {
        dispatch({
          type: ACTIONS.VER_MODAL,
          data: {
            tipo: DocumentosModal.modal.ENVIAR_ARQUIVO,
            dados: {
              path,
              onAddItem,
              colaboradores,
              permissao_pasta_visualizacao,
              mat_planta_proprietario,
            },
          },
        });
      },
    });
    //
    optionsAdminUser.push({
      icon: "RestoreFromTrash",
      title: t("documentos.titleAccessTrash"),
      color: "primary",
      onClick: () => {
        dispatch({
          type: ACTIONS.VER_MODAL,
          data: {
            tipo: DocumentosModal.modal.ACESSAR_LIXEIRA,
            dados: { colaboradores },
          },
        });
      },
    });
  }

  return (
    <Grid container sx={{ background: backgroundPage }}>
      <Grid item xs={isSendFiles ? 12 : 12} sx={{ px: 1 }}>
        <Navegacao
          optionsAdminUser={optionsAdminUser}
          onAddItem={onAddItem}
          path={path}
          onNavegar={onNavegar}
          arquivos={arquivos}
          moverArquivo={moverArquivo}
          setMoverArquivo={setMoverArquivo}
        />
        <GridItensDaPasta
          isMobile={false}
          arquivos={arquivos}
          onNavegar={onNavegar}
          dispatch={dispatch}
          onRecortar={setMoverArquivo}
          itemRecortado={moverArquivo}
          colaboradores={colaboradores}
        />
      </Grid>
    </Grid>
  );
};
// Grid para exibir os itens
const GridItensDaPasta = ({
  isMobile,
  arquivos,
  onNavegar,
  dispatch,
  onRecortar,
  itemRecortado,
  colaboradores,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {arquivos?.length === 0 ? (
        <Stack>
          <AnimacaoSemDados titulo={t("documentos.titleAnimationNoData")} />
        </Stack>
      ) : isMobile ? (
        <List dense>
          {arquivos?.map((ele) => {
            return (
              <Paper
                key={ele.id_arquivo}
                sx={{
                  opacity: itemRecortado === ele.id_arquivo ? 0.5 : 1,
                  mx: itemRecortado === ele.id_arquivo ? 1 : 0,
                }}
                elevation={itemRecortado === ele.id_arquivo ? 4 : 0}
              >
                <ItemArquivoMobile
                  {...ele}
                  onNavegar={
                    itemRecortado === ele.id_arquivo ? () => {} : onNavegar // Evita que exista navegacao para a propria pasta sendo recortada
                  }
                  dispatch={dispatch}
                  onRecortar={onRecortar}
                  itemRecortado={itemRecortado}
                  colaboradores={colaboradores}
                />
              </Paper>
            );
          })}
        </List>
      ) : (
        <Grid container spacing={1}>
          {arquivos?.map((ele) => (
            <Grid key={ele.id_arquivo} item md={3} lg={3} xl={2}>
              <Paper
                key={ele.id_arquivo}
                sx={{
                  height: "100%",
                  opacity: itemRecortado === ele.id_arquivo ? 0.5 : 1,
                  mx: itemRecortado === ele.id_arquivo ? 1 : 0,
                }}
                elevation={itemRecortado === ele.id_arquivo ? 4 : 2}
              >
                <ItemArquivo
                  {...ele}
                  onNavegar={
                    itemRecortado === ele.id_arquivo ? () => {} : onNavegar // Evita que exista navegacao para a propria pasta sendo recortada
                  }
                  dispatch={dispatch}
                  onRecortar={onRecortar}
                  itemRecortado={itemRecortado}
                  colaboradores={colaboradores}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

Documentos.rota = "/documentos_sgsi";

export default Documentos;
