import React, { useCallback, useEffect, useRef } from "react";
import { useFetch } from "../../../../hooks";
import { ToastErro } from "../../../../utils/utils";
import { Confirmar, Pesquisando, QuestionForm } from "../../../../components";
import _ from "lodash";
import EscalonadaQuestionarioRecuperar from "./escalonada-questionario-recuperar";
import axios from "axios";
import { useTheme } from "@mui/material";

const STR = {
  titleConfirmAttach: "Anexar Imagem",
  subtitleConfirmAttach: "Deseja anexar uma imagem ao questionário ? ",
};

const ROUTE = ["/escalonada_questionario_api"];

const sendImageToBucket = async (files) => {
  // Enviar arquivo
  const formData = new FormData();
  [...files].forEach((file) => {
    formData.append("arquivo", file);
  });

  try {
    const response = await axios.post("/bucket", formData);
    return response.data["arquivo"][0].url;
  } catch (error) {
    if (error?.message) {
      ToastErro(error.message);
    } else {
      ToastErro(error);
    }
    return false;
  }
};

function EscalonadaQuestionarioResponder({ id, idCalendar }) {
  return (
    <>
      <EscalonadaQuestionarioRecuperar
        id={id}
        idCalendar={idCalendar}
        render={(data) => (
          <QuestionarioParaEscalonada
            id={id}
            questions={data}
            idCalendar={idCalendar}
          />
        )}
      />
    </>
  );
}

const QuestionarioParaEscalonada = ({ questions, id, idCalendar }) => {
  const isMobile = useTheme()?.isMobile;
  const [allResponses, setAllResponses] = React.useState(null);
  const { wait, setFetch, error, data } = useFetch(ROUTE[0], "POST", "JSON");

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  //
  useEffect(() => {
    if (data) {
      if (isMobile) {
        window.location.href = "/escalonada";
      } else {
        window.location.reload();
      }
    }
  }, [data, isMobile]);

  const onResponseQuestion = useCallback(
    async (response) => {
      const responsesNotNull = [];
      _.keys(response).forEach((idQUestion) => {
        _.keys(response[idQUestion]).forEach((idAsk) => {
          if (response[idQUestion][idAsk]) {
            responsesNotNull.push({
              id_pergunta: idAsk,
              id_questionario: idQUestion,
              resposta: response[idQUestion][idAsk].toString(),
            });
          }
        });
      });

      setAllResponses(responsesNotNull);
    },
    [setAllResponses]
  );
  //
  const onFinishQuestion = useCallback(
    (e, filenamePath) => {
      const obj = {
        id,
        respostas: allResponses,
        id_calendario: idCalendar,
      };
      if (filenamePath) {
        obj.anexo = filenamePath;
      }
      setFetch(obj);
    },
    [allResponses, idCalendar, setFetch, id]
  );

  return (
    <>
      {wait ? (
        <Pesquisando />
      ) : allResponses ? (
        <QuestionarioAnexarImagem onFinish={onFinishQuestion} />
      ) : (
        <QuestionForm
          questions={questions?.questionarios}
          onFinishQuestions={onResponseQuestion}
        />
      )}
    </>
  );
};

const QuestionarioAnexarImagem = ({ onFinish }) => {
  const ref = useRef();
  const onIntentLoadImage = useCallback(() => {
    ref.current.click();
  }, [ref]);
  // Veja se a imagem foi upada
  const onLoadImage = useCallback(
    async (e) => {
      let input = e.target;
      if (input.files && input.files[0]) {
        const filenamePath = await sendImageToBucket(input.files);
        if (filenamePath) {
          onFinish(e, filenamePath);
        }
      } else {
        ref.current.value = "";
      }
    },
    [ref, onFinish]
  );

  return (
    <>
      <input
        accept=".png, .jpg"
        ref={ref}
        onChange={onLoadImage}
        type="file"
        style={{ display: "none" }}
      />
      <Confirmar
        titulo={STR.titleConfirmAttach}
        subtitulo={STR.subtitleConfirmAttach}
        fnCancelar={onFinish}
        fnConfirmar={onIntentLoadImage}
      />
    </>
  );
};

export default EscalonadaQuestionarioResponder;
