import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

function LogisticaBI() {
  const _html =
    '<iframe title="EXPEDIÇÃO LOG TIBERINA" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiNmI5NWY3NDEtNDcxMS00NGE5LTk2ZjUtY2NhNDIyZTgyNGZkIiwidCI6IjgwMTNjOTFhLTRjNzktNDc5ZC1iMjYxLWJjZDNhMDRkM2I5MSJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}

LogisticaBI.rota = "/logistica_bi";

export default LogisticaBI;
