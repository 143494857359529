import axios from "axios";
import { ToastErro } from "../../../utils/utils";
import { toast } from "react-toastify";
// Importar arquivos svg
import PdfImg from "../../../images/pdf.svg";
import PastaImg from "../../../images/pasta.svg";
import DocumentoImg from "../../../images/documento.svg";
import FotoImg from "../../../images/foto.svg";

// Rotas usadas
export const ROTA = "/documentos_registro_sgsi";
export const ROTA_LIXEIRA = "/documentos_registro_sgsi_lixeira";

// redutor
export const ACTIONS = {
  INIT: "INIT",
  ADD: "ADD",
  DEL: "DEL",
  UPD: "UPD",
  UPD_LIST: "UPD_LIST",
  VER_MODAL: "VER_MODAL",
  FECHAR_MODAL: "FECHAR_MODAL",
  SUPER_MODAL: "SUPER_MODAL",
};

// Formatar colaboradores para o select
export const formatColabForSelect = (colabs) => {
  return colabs.map((colab) => [
    `${colab.mat}_${colab.planta}`,
    `${colab.mat} - ${colab.nome}`,
  ]);
};
// Retorna os colaboradores com o nome e avatar
export const getInfoColabs = (colabs) => {
  return colabs.map((colab) => ({
    mat_planta: `${colab.mat}_${colab.planta}`,
    ...colab,
  }));
};

// Upload de arquivo(s)
export const fnUploadArquivos = async (
  formData,
  setProgress,
  addNewData,
  resetField,
  setLoading,
  t
) => {
  try {
    setLoading();
    const resp = await axios.post(ROTA, formData, {
      onUploadProgress: (evt) => {
        let progress = Math.round((evt.loaded * 100) / evt.total);
        setProgress(progress);
      },
    });
    setProgress(null);
    resetField();
    if (resp.status !== 200) {
      ToastErro(t("documentos.errorInternalServer"));
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    //
    addNewData(resp.data.data);
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  } finally {
    setLoading();
  }
};
// Criar pasta
export const fnCriarPasta = async (formData, addNewData, setWait, t) => {
  try {
    setWait();
    const resp = await axios.post(ROTA, formData);

    if (resp.status !== 200) {
      ToastErro(t("documentos.errorInternalServer"));
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo
    addNewData(resp.data.data);
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  } finally {
    setWait();
  }
};
// Exclusão de arquivo/diretorio
export const fnExcluirItem = async (formData, addDelData, t) => {
  try {
    const resp = await axios.delete(ROTA, { data: formData });

    if (resp.status !== 200) {
      ToastErro(t("documentos.errorInternalServer"));
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo
    addDelData();
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  }
};
// Recuperar arquivo/diretorio da lixeira
export const fnRecuperarDaLixeira = async (formData, onNavegar, fecharMenu) => {
  try {
    const resp = await axios.post(ROTA_LIXEIRA, formData);

    if (resp.status !== 200) {
      ToastErro(resp.message);
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    toast.dark(resp.data.sucesso, {
      type: "success",
    });
    // Retorna para o diretorio raiz
    onNavegar("");
    onNavegar("raiz");

    fecharMenu();
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  }
};
// Excluir definitivamente arquivo/diretorio da lixeira
export const fnExcluirDaLixeira = async (formData, fecharMenu) => {
  try {
    const resp = await axios.delete(ROTA_LIXEIRA, { data: formData });

    if (resp.status !== 200) {
      ToastErro(resp.message);
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    toast.dark(resp.data.sucesso, {
      type: "success",
    });
    //
    fecharMenu();
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  }
};
// Mover arquivo/diretorio entre pastas
export const fnMoverArquivo = async (
  formData,
  addNewData,
  fecharRecorte,
  t
) => {
  try {
    const resp = await axios.patch(ROTA, formData);

    if (resp.status !== 200) {
      ToastErro(t("documentos.errorInternalServer"));
      return false;
    }
    if (resp.data?.erro) {
      ToastErro(resp.data.erro);
      return false;
    }
    // Tudo certo
    addNewData(resp.data.data);
    fecharRecorte();
  } catch (error) {
    if (error.response?.data?.message) {
      ToastErro(error.response.data.message);
    } else {
      ToastErro(error);
    }
  }
};

// Funcao que retorna a imagem a ser carregada baseado no tipo de arquivo
export const validaExtensao = (ext) => {
  // Se nao tem extensao é pasta
  if (!ext || ext?.length === 0) {
    return PastaImg;
  }
  switch (ext) {
    case "png":
    case "jpg":
    case "jpeg":
      return FotoImg;
    case "pdf":
      return PdfImg;
    default:
      return DocumentoImg;
  }
};
